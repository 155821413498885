.projects-container {
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: auto auto auto;
    width: 40%;
    margin: auto;
    margin-bottom: 30px;
}

.project-container {
    padding: 1rem;
}

.placeholder:hover {
    cursor: pointer;
    background-color: #660000;
    }

.placeholder {
    border: 1px solid white;
    width: 25vw;
    height: 25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

iframe {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.title {
    position: absolute;
    bottom: -15px;
}

.project-img {
    width: 100%;
    margin-bottom: 2rem;
    border: 1px solid grey;
    border-radius: 1rem;
}

@media only screen and (max-width: 700px) {
    .projects-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .placeholder {
        width: 40vw;
        height: 40vw;
    }
  }

  .project-img {
    object-fit: contain;
    width: 100%;
  }