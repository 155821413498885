.contact-container {
    width: 60vw;
}

.contact, .info {
    box-sizing: border-box;
    padding: 8px;
    margin: auto;
    color: white;
    text-decoration: none;
    width: 50%;
}

.MuiDialogTitle-root {
    text-align: center;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact:hover {
    background-color: white;
    color: black;
    cursor: pointer;
}

.MuiDialog-paper {
    width: 40vw;
    height: 50vh;
    padding: 50px;
}

p {
    text-align: center;
    padding-bottom: 3px;
}

.warning-text {
    color: red
}

input, textarea {
    padding: 10px;
    margin: 10px;
}