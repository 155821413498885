.App {
  text-align: center;
  margin-top: 100px;
}

::-webkit-scrollbar {
  display: none;
}

img {
  height: 50vh;
}
