header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    z-index: 99;
    background-color: black;
}

header * {
    text-decoration: none;
    font-weight: bold;
    color: white;
}

ul {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    margin-top: 0px;
    padding-left: 0px;
}

a {    
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

li {
    list-style-type: none;
}

nav {
    height: 100%;
}

.active {
    background-color: #660000;
    box-shadow: 0 4px 2px -2px white;
}

p {
    margin-top: 25px;
}